<template>
  <div class="account"
      v-loading='data_running'>
    <!-- 顶部的账户余额数据 -->
    <div class="data_box">
      <content-title
        :title="$t('message.payment')"
        :next_page="$t('message.paymentPreferences')"
        @nextPage="nextPage()"
      ></content-title>
      
      <div class="data_content flexCenter-jc-sb flexCenter-ai-center">
        <div class="balance flexCenter-ai-center flex-justify-content flex-column">
          <div class="number">{{money_data.account_balance}}</div>
          <div class="text">{{$t('message.accountBalance')}}</div>
        </div>
        <div class="remit flexCenter-ai-center flex-justify-content flex-column">
          <div class="number">{{money_data.settle_balance}}</div>
          <div class="text">{{$t('message.paid ($)')}}</div>
        </div>
      </div>
    </div>

    <!-- 账户转账记录列表 -->
    <div class="remit_box">
      <content-title
        :title="$t('message.history')"
      ></content-title>
      <div class="remit_list_box" v-if="money_data.list.length > 0">
        <div class="remit_title flexCenter-ai-center">
          <span class="flexCenter" style="width: 7.5%">{{$t('message.number')}}</span>
          <span class="flexCenter" style="width: 20.8%">{{$t('message.date1')}}</span>
          <span class="flexCenter" style="width: 12.5%">{{$t('message.amount')}}</span>
          <span class="flexCenter" style="width: 12.5%">{{$t('message.commission')}}</span>
          <span class="flexCenter" style="width: 12.5%">{{$t('message.status')}}</span>
          <span class="flexCenter" style="width: 12.5%">{{$t('message.paymentMethod')}}</span>
          <span class="flexCenter" style="width: 21.6%">{{$t('message.note')}}</span>
        </div>
        <div class="remit_list">
          <div class="remit_item flexCenter-ai-center" v-for="(item, index) in money_data.list" :key="item.id">
            <span class="flexCenter" style="width: 7.5%">{{index + 1}}</span>
            <span class="time flexCenter" style="width: 20.8%">{{item.date_period}}</span>
            <span class="flexCenter" style="width: 12.5%">{{item.charge_amount}}</span>
            <span class="flexCenter" style="width: 12.5%">{{item.settle_commission}}</span>
            <span class="flexCenter" style="width: 12.5%">{{ item.pay_status === 1 ? $t('message.paid') : $t('message.failure')}}</span>
            <span class="flexCenter" style="width: 12.5%">{{ item.pay_type === 1 ? $t('message.debitCard') : $t('message.Alipay')}}</span>
            <div class="cause flexCenter" style="width: 21.6%">
               <el-popover
                placement="bottom-start"
                width="250"
                trigger="hover"
                :content="item.remark">
                <span slot="reference">{{item.remark}}</span>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="sizeChange"
            :hide-on-single-page="true"
            :total="count"
            :page-size="data.limit"
          >
          </el-pagination>
        </div>
      </div>
      <div class="empty flexCenter flex-column" v-if="is_data">
        <img src="@/assets/images/empty.png" alt="">
        <span>{{$t('message.noMoreData')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import contentTitle from "@/components/contentTitle/contentTitle"; //顶部栏
import { settle } from '@/apis/api'
export default {
  name: 'account',
  data () {
    return {
      count: 0,
      data: {
        page: 1,
        limit: 20,
      },
      money_data: {
        account_balance: 0, // 账户余额
        settle_balance: 0, // 已打款
        list: []
      },
      is_data: false, // 是否加载无数据布局
      data_running: false, 
    }
  },
  created () {
    this.settleData()
  },
  methods: {
    nextPage() {
      // window.open(window.location.host + '/#/user')
      if (this.$store.state.userInfo.sign_status == 1) {
        this.$message.error({
          message: this.$t('messages.thisServiceIsNoLongerAvailableAndTheDataNoLongerUpdated'),
          type: 'error'
        })
        return
      }
      window.open('/#/user')
    },
    // 分页
    sizeChange(v) {
      this.data.page = v;
      this.getList(this.params);
    },
    // 获取数据
    async settleData () {
      this.data_running = true
      let params = {
        page: this.data.page,
        limit: this.data.limit,
      }
      let res = await settle(params)
      if (res.code === 1) {
        this.money_data = res.data
        res.data.le
      }
      res.data.list.length > 0 ? this.is_data = false : this.is_data = true
      this.data_running = false
      console.log(res)
    }
  },
  components: {
    contentTitle,
  },
}
</script>
<style lang="scss" scoped>
.account {
  .data_box {
    .data_content {
      >div {
        width: 49%;
        height: 160px;
        background-color: #fff;
        border-radius: 8px;
        .number {
          color: #15161A;
          font-size: 36px;
          font-weight: bold;
        }
        .text {
          color: #65676E;
          font-size: 14px;
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }
  }

  .remit_box {
    margin-top: 50px;
    .remit_list_box {
      margin-top: 30px;
      border-radius: 10px;
      background: #ffffff;
      // height: 660px;
      .remit_title {
        height: 60px;
        > span {
          text-align: left;
          color: #15161a;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .remit_list {
        line-height: 30px;
        .remit_item {
          height: 54px;
          background-color: #fff;
          >span {
            color: #32343A;
          }
        }
        .remit_item:nth-child(odd) {
          background-color: #f9f9f9;
        }
        .remit_item:hover {
          background-color: #F3F6FF;
        }
      }

      .pagination {
        padding-bottom: 40px;
        margin-top: 40px;
      }
    }

    .empty {
      height: 500px;
      border-radius: 10px;
      background: #ffffff;
      img {
        width: 140px;
      }
      span {
        color: #9497A1;
        font-size: 14px;
      }
    }
  }
}
</style>